import { getModule } from "./tenant";

const moduleLanguage = {
  'brazil': 'pt-BR',
  'indonesia': 'id'
};

const localeContentField = {
  'pt-BR': 'portugueseContent',
  'en': 'englishContent',
  'id': 'indonesianContent',
};

const moduleCoordinates = {
  brazil: {
    lat: -14.392118083661728,
    lng: -56.25000000000001,
  },
  indonesia: {
    lat: -2.2689154,
    lng: 95.9612127,
  }
};

const moduleServerLayers = {
  brazil: {
    prefix: 'mapbiomas-alertas',
    production: 'dashboard-alerts',
    default: 'dashboard-alerts-staging',
  },
  indonesia: {
    prefix: 'mapbiomas-alerta-indonesia',
    production: 'dashboard-alerts-staging-indonesia',
    default: 'dashboard-alerts-staging-indonesia'
  }
}

export const CURRENT_MODULE = getModule();
export const DEFAULT_LANGUAGE = moduleLanguage[getModule()] || 'pt-BR';
export const MODULE_COORDINATES = moduleCoordinates[CURRENT_MODULE];
export const MODULE_SERVER_LAYER = moduleServerLayers[CURRENT_MODULE][process.env.NODE_ENV] || moduleServerLayers[CURRENT_MODULE].default;
export const MODULE_SERVER_LAYER_PREFIX = moduleServerLayers[CURRENT_MODULE].prefix;

export const isModule = (module) => {
  if (Array.isArray(module)) {
    return modules.includes(getModule());
  }
  return getModule() === module;
};

export const getLocaleContentField = (data) => {
  const fallbackLocale = isModule('brazil') ? 'pt-BR' : 'en';
  const locale = localStorage.getItem('locale') || fallbackLocale;
  return data[localeContentField[locale]] || data[localeContentField[fallbackLocale]];
}